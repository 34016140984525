var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pl-8 pr-8"},[_c('div',{staticClass:"mb-xl-margin-file-sign"},[_c('v-row',{staticClass:"mt-10"},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"6"}},[_c('h1',{staticClass:"reference__title"},[_vm._v("Detalle solicitud general")])]),_c('v-col',{staticClass:"text-left",attrs:{"cols":"6"}},[_c('h1',{staticClass:"reference__title"},[_vm._v("Feedback")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"reference__card px-8 mt-8",attrs:{"outlined":"","elevation":"0"}},[_c('v-row',{staticClass:"mb-8",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"12"}},[_c('ProcessesDetails',{attrs:{"dataProcess":_vm.dataProcess}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"reference__card px-8 mt-8",attrs:{"outlined":"","elevation":"0"}},[_c('v-row',{staticClass:"mb-8",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"12"}},[_c('FeedbackDetails',{attrs:{"dataProcess":_vm.dataProcess,"dataHistorys":_vm.dataHistorys,"show":false,"showInfo":false},on:{"dataFeedback":_vm.dataFeedback}})],1)],1)],1)],1)],1),(this.statusID==8)?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"pa-xl-0 mt-10",staticStyle:{"color":"#fff"},style:({
              'background-color': hover ? '#324CA1' : '#466BE3',
            }),attrs:{"block":"","rounded":""},on:{"click":function($event){return _vm.sendDevelpoment()}}},[_vm._v(" Enviar a producción ")])]}}],null,false,2415820970)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-btn',{staticClass:"pa-xl-0 mt-10",staticStyle:{"color":"#fff"},style:({
              'background-color': hover ? '#324CA1' : '#466BE3',
            }),attrs:{"block":"","rounded":""},on:{"click":function($event){return _vm.sendTest()}}},[_vm._v(" Enviar a pruebas ")])]}}],null,false,2518924069)})],1)],1):_vm._e(),(this.statusID==11)?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-btn',{staticClass:"pa-xl-0 mt-10",staticStyle:{"color":"#fff"},style:({
              'background-color': hover ? '#324CA1' : '#466BE3',
            }),attrs:{"block":"","rounded":""},on:{"click":function($event){return _vm.sendDevelpoment()}}},[_vm._v(" Enviar a producción ")])]}}],null,false,2415820970)})],1)],1):_vm._e(),(this.statusID==10)?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-btn',{staticClass:"pa-xl-0 mt-10",staticStyle:{"color":"#fff"},style:({
              'background-color': hover ? '#324CA1' : '#466BE3',
            }),attrs:{"block":"","rounded":""},on:{"click":function($event){return _vm.sendTest()}}},[_vm._v(" Enviar a pruebas ")])]}}],null,false,2518924069)})],1)],1):_vm._e()],1),_c('Alert',{attrs:{"open":_vm.message.dialog,"text":_vm.message.sms,"title":_vm.message.title,"type":_vm.message.type,"redirect":_vm.message.redirect},on:{"close":function($event){_vm.message.dialog = false}}}),_c('v-overlay',{attrs:{"value":_vm.loadingAll}},[_c('v-progress-circular',{attrs:{"size":120,"color":"#466BE3","indeterminate":""}},[_vm._v(" Cargando... ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }